body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#background {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

#background img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#content-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

#website-content {
  position: absolute;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  transform-origin: top left;
}

#keyboard {
  position: absolute;
  z-index: 2;
}

.paw {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  transition: transform 0.1s ease;
  transform: translate(50px, 0);
  opacity: 1; /* Make sure the paw is visible */
}

.right-paw {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  transition: transform 0.1s ease;
  transform: translate(100px, 0);
}

.key-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.keyboard-outline {
  fill: none;
}


.mobile-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.mobile-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../public/IMG_1415.PNG');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* This creates a 50% black overlay */
}

/* ... (keep all existing styles) */

.mobile-message {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
  color: #fff;
  z-index: 1; /* Ensures the message is above the background and overlay */
}

.mobile-message p {
  font-size: 5vw; /* Responsive font size */
  max-width: 80%;
  line-height: 1.5;
  margin-bottom: 20px; /* Add some space between the text and icons */
}

/* ... (keep all existing styles) */

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%; /* Ensure it spans the full width of the container */
}

.social-icon {
  width: 40px;
  height: 40px;
  margin: 0 10px;
  object-fit: cover; /* Changed from contain to cover */
  transition: transform 0.3s ease;
  border-radius: 50%; /* This makes the icons circular */
  border: 2px solid #fff; /* Add a white border */
  overflow: hidden; /* Ensure the image doesn't spill outside the circular border */
}

.social-icon:hover {
  transform: scale(1.1);
}

@media (min-width: 768px) {
  .mobile-message p {
    font-size: 24px; /* Cap the font size for larger screens */
  }

  .social-icon {
    width: 50px;
    height: 50px;
  }
}