/* Terminal.css */

.terminal-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: rotate(-4deg); /* Increased rotation */
    transform-origin: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    scale: 0.85;
  }
  
  #terminal {
    position: relative;
    z-index: 1;
    padding: 2%;
    box-sizing: border-box;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 0.9em;
    line-height: 1.2;
    overflow-y: scroll; /* Changed from auto to scroll */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    background-color: rgba(0, 0, 0, 0.7);
    width: 90%;
    height: 90%;
    max-height: 90%;
    font-family: 'Roboto Mono', monospace;
    color: #0f0;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  #terminal::-webkit-scrollbar {
    display: none;
  }
  #logo,
  #logo2 {
    pointer-events: none;
    position: absolute;
    right: 2%;
    width: 15%; /* Increased image size */
    height: auto;
    z-index: 1001;
    transition: opacity 0.5s ease-out;
  }
  
  #logo {
    top: 2%;
    transform: rotate(7deg); /* Slightly increased rotation */
  }
  
  #logo2 {
    top: 20%;
    transform: rotate(-7deg); /* Slightly increased rotation */
  }
  
  .cursor {
    animation: blink 0.7s infinite;
  }
  
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  #homepage {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    color: #0f0;
    font-family: 'Roboto Mono', monospace;
    overflow: hidden;
    z-index: 2000;
  }
  
  .matrix-rain {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  #closeHomepage {
    position: fixed;
    top: 2%;
    right: 2%;
    background-color: #0f0;
    color: #000;
    border: none;
    padding: 1% 2%;
    cursor: pointer;
    z-index: 2001;
  }
  
  .terminal-image {
    max-width: 100%;
    filter: sepia(100%) hue-rotate(50deg) saturate(500%) brightness(75%);
  }
  
  .rainbow-text {
    background-image: linear-gradient(
      to right,
      #ff3333,
      #ffcc33,
      #ffff33,
      #99ff33,
      #33ff33,
      #33ff99,
      #33ffff,
      #3399ff,
      #3333ff,
      #9933ff,
      #ff33ff,
      #ff3399
    );
    -webkit-background-clip: text;
    color: transparent;
    animation: rainbow-animation 20s linear infinite;
    background-size: 1200% 100%;
    filter: brightness(1.5);
  }
  
  @keyframes rainbow-animation {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 1200% 50%;
    }
  }
  
  #particles-js {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
  }
  