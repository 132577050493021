/* src/AudioPlayer.css */
.audio-player-container {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 300px;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }

  .audio-player-container.visible {
    opacity: 1;
    visibility: visible;
  }
  
.audio-player {
    background-color: rgba(0, 0, 0, 0.7);
    border: 2px solid #0f0;
    border-radius: 15px;
    padding: 10px;
    transition: box-shadow 0.3s ease;
  }
  
  .player-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .play-pause {
    background-color: transparent;
    border: none;
    color: #0f0;
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .progress-bar {
    flex-grow: 1;
    height: 5px;
    background-color: #333;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 10px;
  }
  
  .progress {
    height: 100%;
    background-color: #0f0;
  }
  
  .time-display {
    color: #0f0;
    font-family: 'Courier New', monospace;
    font-size: 12px;
    margin-right: 10px;
  }
  
  .volume-slider {
    width: 80px;
    -webkit-appearance: none;
    background: #333;
    outline: none;
    border-radius: 5px;
    height: 5px;
  }
  
  .volume-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: #0f0;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .volume-slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: #0f0;
    cursor: pointer;
    border-radius: 50%;
  }
