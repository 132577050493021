/* src/Playlist.css */
.playlist {
    background-color: rgba(0, 0, 0, 0.7);
    border: 2px solid #0f0;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 10px;
    color: #0f0;
    font-family: 'Courier New', monospace;
  }
  
  .playlist h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .playlist ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .playlist li {
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .playlist li:hover {
    background-color: rgba(0, 255, 0, 0.2);
  }
  
  .playlist li.active {
    background-color: rgba(0, 255, 0, 0.4);
  }